.contractActions{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
}

/* .far.fa-question-circle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
} */
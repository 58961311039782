// .big {
//   font-size: 24px;
// }

.skeleton {
  position: relative;
  background: #ccc;
  display: inline-block;
  min-height: 1.4em;
  border-radius: 4px;
  overflow: hidden;
}

.skeleton:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  width: 50px;
  left: -50px;
  background: linear-gradient(to right, rgba(white, 0), rgba(white, .3), rgba(white,0));
  animation: loading 1s infinite;
}

@keyframes loading {
  0%   { 
    transform: translateX(0); 
  }
  50% { 
    opacity: 1;
  }
  100% { 
    opacity: 0;
    transform: translateX(1500px); 
  }
}
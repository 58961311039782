.trigger {
  display: none !important;
}

/* .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #50b748;
} */

.breadcrumb {
  margin: 10px;
}

.logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: "dark";
  cursor: pointer;
  transition: all 0.3s;
}

.logo img {
  display: inline-block;
  height: 34px;
  vertical-align: middle;
}
.logo h1 {
  display: inline-block;
  margin: 0 0 0 20px;
  color: rgb(255, 255, 255);
  font-weight: 550;
  font-size: 30px;
  vertical-align: middle;
}

.ant-menu-submenu > .ant-menu {
  background-color: rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
}
.ant-menu-submenu-title:hover {
  background-color:  #50b748 !important;
  border-radius: 5px;
}
.ant-menu-item:hover {
  background-color:  #50b748 !important;
  border-radius: 5px;
}

.ant-dropdown-menu-item:hover {
  background-color:  #96cc91 !important;
  border-radius: 5px;
}
.ant-dropdown-trigger:hover {
  background-color:  #96cc91 !important;
  border-radius: 5px;
}

.ant-btn:hover {
  background-color:  #86f77e !important;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
  .trigger {
    display: block !important;
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #50b748;
  }
}

@media only screen and (max-width: 873px) {
  .carousel-container {
    display: none !important;
  }
  .login-container {
    width: 100% !important;
  }
}

.ant-carousel .slick-slide .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
}

.ant-carousel .slick-slide .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  background-color: rgb(0, 0, 0);
}

.ant-carousel .slick-slide .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.ant-carousel .slick-slide .carousel-caption h3 {
  color: #fff;
  font-size: 1.75rem;
}

.ant-carousel .slick-slide .carousel-caption p {
  color: #fff;
  margin-top: 0;
  margin-bottom: 1rem;
}

.ant-carousel .slick-slide .carousel-item img {
  height: 100vh;
  width: 100%;
  opacity: 0.5;
}

.menuLang {
  margin-top: 23px !important;
  margin-right: 5px !important;
}

.green .ant-radio-inner {
  border-color: green;
  border-width: 2px;
}
.orange .ant-radio-inner {
  border-color: orange;
  border-width: 2px;
}
.red .ant-radio-inner {
  border-color: red;
  border-width: 2px;
}
.black .ant-radio-inner {
  border-color: black;
  border-width: 2px;
}

.label-top {
  transform: translateY(-5px);
}
.label-bottom1 {
  transform: translateY(15px);
}
.label-bottom2 {
  transform: translateY(20px);
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  background-color: transparent;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
.leaflet-div-icon {
    background: transparent;
    border: none;
}

.ant-card-actions {
  background-color: #fff;
}
